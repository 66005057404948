/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import UserLeft from '../PersonUserLeft.vue'
import CheckInService from '../../../api/CheckInService.js'
import cropcss from '../../../util/croppie/croppie.css'
import cropjs from '../../../util/croppie/croppie.min.js'
import CutImg from '../CutImg.vue'

export default{
	name:'EditPerUser',
	data(){
		return{
			userInfo:'',//用户信息
			userAvtor:'',//用户头像
			userName:'',
			userSex:-1,
			userBirth:'',
			cutImg: '',
			ranger: { //限制可选日期
				disabledDate(time) {
					var startTime = new Date('1899-12-31');
					return time.getTime() > Date.now() || time.getTime() < startTime;
				},
			},
		}
	},
	methods:{
		GetUserInfo(){
			var that = this
			CheckInService.GetUserPerInfo(that.$UserID).then(res=>{
				if(res.Tag == 1){
					that.userInfo = res.Result
					that.userAvtor = that.userInfo.Avatar
					that.userName = that.userInfo.UserName
					that.userSex = that.userInfo.Sex?that.userInfo.Sex:-1,
					that.userBirth = that.userInfo.Birthday
				}
			})
		},
		uploadImg(e, num) {
			var _this = this;
			//上传图片 
			var file = e.target.files[0]
			_this.fileName = file.name;
			if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
				_this.$alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种', '提示', {
					confirmButtonText: '确定'
				});
				return false
			}
			var reader = new FileReader();
			reader.onload = (e) => {
				let data;
				if (typeof e.target.result === 'object') {
					// 把Array Buffer转化为blob 如果是base64不需要 
					data = window.URL.createObjectURL(new Blob([e.target.result]))
				} else {
					data = e.target.result
				}
				if (num === 1) {
					_this.$refs.CutImgPop.option.img = data
					_this.$refs.CutImgPop.CutImgPopup = true;
				} else if (num === 2) {
					_this.$refs.CutImgPop.example2.img = data
					_this.$refs.CutImgPop.CutImgPopup = true;
				}
		
			}
			reader.readAsArrayBuffer(file);
			_this.$refs.CutImgPop.CutImgPopup = true;
		},
		uploadCutImg() {
			var that = this
			CheckInService.UserAvator(that.cutImg.substring(that.cutImg.indexOf("base64") + 7), 'Avatar').then(
				res => {
					if (res.Tag == 1) {
						that.userAvtor = res.Message
					} else {
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定',
						});
					}
				})
		},
		chooseUserSex(sex){
			this.userSex = sex
		},
		SaveEdit(){
			var that = this
			if(that.userName.trim().length == 0){
				$('#username').text("昵称不能为空")
				return
			}else if(that.userName.trim().length > 12){
				$('#username').text("昵称最多12个字符")
				return
			}
			$('#username').text("")
			CheckInService.UserPerEdit(that.$UserID,that.userName,that.userSex,that.userBirth,that.userAvtor).then(res=>{
				if(res.Tag == 1){
					this.$router.go(-1)
				}else{
					that.$alert('修改失败', '提示', {
						confirmButtonText: '确定'
					});
				}
			})
		},
		CancelEdit(){ //取消编辑
			this.$router.go(-1)
		}
	},
	created(){
		this.GetUserInfo()
	},
	components:{
		'Header':Header,
		'Footer':Footer,
		'UserLeft':UserLeft,
		'CutImg': CutImg
	}
}